import React from 'react';

const Speakers = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Keynote Speakers</h1>
       
       {/* Dr. C. Mohan */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. C. Mohan</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_c_mohan.jpg" 
            alt="Dr. C. Mohan" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p className="mb-2">
              <strong>Biography:</strong> Dr. C. Mohan is a Distinguished Professor of Science at Hong Kong Baptist University and a retired IBM Fellow. With over four decades of experience, he is a prominent figure in database systems and intelligent computing. Dr. Mohan’s work has had a transformative impact on transaction management, cloud databases, and the integration of AI with data management. He has published extensively and is widely recognized for his contributions to both academia and industry.
            </p>
            <p className="mb-2">
              <strong>Topic:</strong> <u>Data Management Implications of Intelligent Computing</u>
            </p>
            <p>
              <strong>Abstract:</strong> After summarizing the relevant aspects of a relatively new paradigm of computing, namely Intelligent Computing (IC), as described by some of the leading IC researchers, I will discuss many recent developments in the data management area that have relevance to IC. As someone who has been, and who continues to be, active as an individual contributor in the database (DB) area for over four and a half decades, in this talk, I will give a broad overview of the evolution of the data landscape. I will discuss not only research topics but also the trends in the commercial and standards arenas. Some of the topics that will be covered are cloud DBMSs, hardware-software co-design, multi modal integrated DBMSs, deeper integration of AI and DBs (including Large Language Models), vector indexes, and edge analytics.
            </p>
          </div>
        </div>
      </section>
      
      {/* Prof. Shui Yu */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Prof. Shui Yu</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_shui_yu.jpg" 
            alt="Prof. Shui Yu" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p className="mb-2">
              <strong>Biography:</strong> Shui Yu is a Professor of School of Computer Science, University of Technology Sydney, Australia. His research interests include Cybersecurity, Network Science, Big Data, and Mathematical Modelling. He has published seven monographs and edited two books, more than 600 technical papers at different venues, such as IEEE TDSC, TPDS, TC, TIFS, TMC, TKDE, TETC, ToN, and INFOCOM. His current h-index is 80. Professor Yu promoted the research field of networking for big data since 2013, and his research outputs have been widely adopted by industrial systems, such as Amazon cloud security. He is currently serving the editorial boards of IEEE Communications Surveys and Tutorials (Area Editor) and IEEE Internet of Things Journal (Editor). He is a Distinguished Visitor of IEEE Computer Society, and an elected member of Board of Governors of IEEE VTS and IEEE ComSoc, respectively. He is a member of ACM and AAAS, and a Fellow of IEEE.
            </p>
            <p className="mb-2">
              <strong>Topic:</strong> <u>Mathematical Artificial Intelligence and Applications</u>
            </p>
            <p>
              <strong>Abstract:</strong> Artificial Intelligence is a leading topic in both academia and industry. However, we do not have a decent theoretical understanding of the various models, such as the deep learning models. As a matter of fact, we are at the doorstep of the theoretical breakthrough for AI. In this talk, we firstly present the basic concepts of differential geometry, a powerful tool for the study of high dimension space. Secondly, we discuss some applications of differential geometry for unprecedented AI problems. We hope and believe the talk will shed light on the promising field for an interested audience.
            </p>
          </div>
        </div>
      </section>
      
      {/* Prof. K. Gopakumar */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Prof. K. Gopakumar</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_gopakumar.jpg" 
            alt="Prof. K. Gopakumar"
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p className="mb-2">
              <strong>Biography:</strong> Prof. K. Gopakumar is an Emeritus Professor at IISc, Bangalore, and a leading expert in power electronics and motor drive systems. His research focuses on multilevel inverter topologies for industrial applications, renewable energy systems, and energy-efficient designs. Prof. Gopakumar’s work has paved the way for innovative approaches in harmonic suppression and medium-voltage drives.
            </p>
            <p className="mb-2">
              <strong>Topic:</strong> <u>Multilevel inverters for drives application using open-end winding configuration for motor with extended linear sinusoidal modulation control</u>

            </p>
            <p>
              <strong>Abstract:</strong> Recently, numerous interesting multilevel topologies have been reported for motor drive applications. However, to date, the most popular topology is the neutral point clamped (NPC) three-level topology. Especially for medium-voltage drives applications. This shows that the industry is still looking for viable alternative to this topology, with reduced power circuit complexity and with extended linear sinusoidal modulation range for drives applications. This lecture will focus on some of the recent work from my lab on cascaded basic inverter topologies with reduced DC-link voltages, enabling multilevel back- to- back inverter operation for medium voltage drives. This is followed by some recent work on multilevel topologies, with stacked low voltage inverter cells, polygonal voltage space vector generation with dodecagonal, octadecagonal and 24-sided structures for variable speed drives applications with low order harmonic suppression using the concept of switched capacitive filters.
            </p>
          </div>
        </div>
      </section>

      {/* Prof. Ram Bilas Pachori */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Prof. Ram Bilas Pachori</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_ram_bilas_pachori.jpg" 
            alt="Prof. Ram Bilas Pachori" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p className="mb-2">
              <strong>Biography:</strong> Prof. Ram Bilas Pachori is a faculty member at IIT Indore, specializing in multichannel signal processing and its applications in healthcare. His research includes advanced techniques like empirical wavelet transform and multivariate adaptive signal decomposition. Prof. Pachori’s work has been instrumental in developing intelligent systems for diagnosing neurological conditions and enhancing brain-computer interfaces.
            </p>
            <p className="mb-2">
              <strong>Topic:</strong> <u> Multichannel Signal Processing Based Intelligent Systems for Medical Applications </u>
            </p>
            <p>
              <strong>Abstract:</strong> In the last one or two decades, adaptive signal decomposition techniques have gained popularity for their broad applicability to almost all fields of science and technology. Empirical mode decomposition has been proposed to decompose the signal into amplitude-frequency modulated components (basis functions). Several methods have been proposed, followed by empirical mode decomposition for adaptive decomposition and to obtain improved signal representation. Empirical wavelet transform (EWT), Fourier-Bessel series expansion-based EWT (FBSE-EWT), iterative filtering, variational mode decomposition are a few popular techniques among adaptive decomposition techniques. Recent advancements in sensor technology make it easier to acquire signals from multiple sources simultaneously, which demands multivariate/multi-channel signal decomposition methods. The univariate iterative filtering has been extended for processing multichannel signals, which will be discussed in this talk. Also, developed intelligent systems based on multivariate iterative filtering and machine learning for brain-computer interface and schizophrenia detection from multichannel electroencephalogram (EEG) signals will be presented. The obtained results show the effectiveness of the discussed multivariate/multi-channel adaptive signal decomposition techniques.
            </p>
          </div>
        </div>
      </section>

      {/* Dr. John Jose */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. John Jose</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_john_jose.jpg" 
            alt="Dr. John Jose" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p className="mb-2">
              <strong>Biography:</strong> Dr. John Jose is an Associate Professor in the Department of Computer Science and Engineering at IIT Guwahati. His research areas include hardware accelerators, dataflow architectures, and in-memory computing technologies. Dr. Jose’s innovative contributions address the computational challenges of AI and ML applications, aiming to improve energy efficiency and performance in large-scale systems.

            </p>
            <p className="mb-2">
              <strong>Topic:</strong> <u>Specific Hardware Systems for AI and ML Applications</u>
            </p>
            <p>
              <strong>Abstract:</strong> Deep Neural Networks (DNNs) have revolutionized fields such as Computer Vision, Natural language Processing, Machine Translation, Speech Recognition, and more, often outperforming human experts. This exceptional performance, however,comes with a trade-off of high computational complexity and extensive data movement due to the growing size of network models and input data. As a result, traditional processors like CPUs and GPUs struggle to handle large-scale neural networks, leading to performance and cost-effectiveness issues. To overcome this, researchers have focused on developing DNN accelerators, which are further classified into broadly 2 categories - Dataflow Architectures and In-memory computing Technologies. The heart of accelerators is MAC units which require memory reads for inputs and weights and memory write to store the output. These time-consuming memory accesses can severely impact both throughput and energy efficiency. Accelerators provide an opportunity to reduce the energy cost of data movement by introducing several levels of local memory hierarchy with different energy costs a promising paradigm that integrates computation and storage, reducing the need for data movement. In-memory Computing based accelerator designs has come up as a better alternative to Von-Neumann and Spatial architectures by performing in and near memory computation, massively reducing data movements.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Speakers;
