import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaCalendarAlt } from 'react-icons/fa';

const ImportantDates = () => {
  const dates = [
    { date: 'July 5', event: 'Call for Papers Announcement' },
    { date: 'July 10', event: 'Draft Paper Submission opens' },
    { date: 'October 15', event: 'Draft paper submission closes' },
    { date: 'November 15', event: 'Notification of Acceptance' },
    { date: 'December 20', event: 'Camera ready paper submission deadline' },
    { date: 'January 25', event: 'Registration Deadline' }, // Added this line
  ];

  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Important Dates</h1>
      <VerticalTimeline
        lineColor="#2563EB" // Custom vertical line color to match text-blue-600
      >
        {dates.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              background: '#E0F2FE', // Light blue background for card
              color: '#2563EB', // Blue text color for the content
              borderRadius: '12px', // Rounded corners for a smoother look
            }}
            contentArrowStyle={{ borderRight: '7px solid #E0F2FE' }}
            date={item.date}
            dateClassName="text-blue-600 font-bold text-lg" // Make the dates more noticeable
            iconStyle={{
              background: '#2563EB', // Icon background matches the vertical line
              color: '#fff', // White icon color for contrast
            }}
            icon={<FaCalendarAlt />}
          >
            <h3 className="text-lg font-bold text-blue-600">{item.event}</h3>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default ImportantDates;
