import React from 'react';
import { Typography, Box, Paper, Divider } from '@mui/material';

const Contact = () => {
  return (
    <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <h1 className="text-blue-600 text-4xl font-bold mb-6">Contact Us</h1>

      <Paper sx={{ padding: 3, marginBottom: 3, width: '100%', maxWidth: 650 }}>
        <Typography variant="h5" gutterBottom>
          Contact Information
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Email:</strong>{' '}
          <a href="mailto:etis2025@ktu.edu.in" style={{ color: '#1976d2' }}>
            etis2025@ktu.edu.in
          </a>
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Mob:</strong> 9446808243 (Dr. Biju K, Organizing Secretary) -{' '}
          <em>WhatsApp also available</em>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Mob:</strong> 9846121223 (Dr. Vinodkumar Jacob, Convenor)
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Mob:</strong> 7907475861 (Dr. Venugopal G, General Chair)
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Mob:</strong> 9447791946 (Prof. Sanjeev G, Publicity Chair)
        </Typography>
      </Paper>

      <Paper sx={{ padding: 3, marginBottom: 3, width: '100%', maxWidth: 650 }}>
        <Typography variant="h5" gutterBottom>
          Address
        </Typography>
        <Typography variant="body1" paragraph>
          APJ Abdul Kalam Technological University
          <br />
          CET Campus,
          <br />
          Alathara Rd,
          <br />
          Ambady Nagar,
          <br />
          Thiruvananthapuram,
          <br />
          Kerala 695016
        </Typography>
      </Paper>

      <Divider sx={{ marginBottom: 3, width: '80%' }} />

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31564.365002130533!2d76.88731165513468!3d8.543414205759552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05beb9c24000bf%3A0x1147a2f2deda7d76!2sAPJ%20Abdul%20Kalam%20Technological%20University!5e0!3m2!1sen!2sin!4v1725043517517!5m2!1sen!2sin"
          width="80%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="APJ Abdul Kalam Technological University"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Contact;
