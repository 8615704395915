import React, { useState, useEffect } from 'react';

const Carousel = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date('2025-02-07T00:00:00').getTime();
    const now = new Date().getTime();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const svgStyle = {
    animation: 'moveGraphic 20s ease-in-out infinite',
    position: 'absolute',
    top: 0,
    left: '-20%',
    width: '140vw',
    height: '140vh',
    objectFit: 'cover',
    transformOrigin: 'center center',
    transform: 'scale(1.3)',
  };

  return (
    <div className="w-full">
      <style>
        {`
          @keyframes moveGraphic {
            0% {
              transform: translateX(0) scale(1.3);
            }
            50% {
              transform: translateX(-5%) scale(1.3);
            }
            100% {
              transform: translateX(0) scale(1.3);
            }
          }
        `}
      </style>

      {/* Section 1: SVG Art and Event Details */}
      <section className="relative w-full h-screen overflow-hidden bg-blue-800 text-white">
        <svg
          viewBox="0 0 1600 900"
          xmlns="http://www.w3.org/2000/svg"
          style={svgStyle}
        >
          <path
            d="M0 450C300 250 600 50 900 250C1200 450 1500 250 1800 450V900H0V450Z"
            fill="#1E3A8A"
          />
          <path
            d="M0 450C300 150 600 -50 900 150C1200 350 1500 150 1800 250V900H0V450Z"
            fill="#2563EB"
            opacity="0.7"
          />
          <path
            d="M100 450C400 200 700 0 1000 200C1300 400 1600 200 1900 450V900H100V450Z"
            fill="#3B82F6"
            opacity="0.5"
          />
          <circle cx="900" cy="450" r="360" fill="#BFDBFE" opacity="0.3" />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center text-center">
          <div className="relative z-10">
            <h1 className="text-3xl md:text-5xl font-bold">
              International Conference on <br /> Emerging Technologies for Intelligent Systems <br /> (ETIS 2025)
            </h1>
            <p className="mt-4 text-lg md:text-2xl font-semibold">
              Dates: 7, 8, 9 February 2025<br /> Venue: Mar Baselios College of Engineering And Technology, Trivandrum
            </p>
          </div>
        </div>
      </section>

            {/* Section 2: Sponsors */}
            <section className="py-12 bg-gray-100 text-gray-800">
        <div className="text-center">
          <h2 className="text-blue-600 text-4xl font-bold mb-6">Our Sponsors</h2>
          <div className="flex flex-wrap justify-center gap-6">
            <div className="bg-white shadow-md rounded-lg p-4 w-60">
              <h3 className="text-lg font-bold text-yellow-500">Gold (5 Lakhs)</h3>
              <ul className="text-left mt-2">
                <li className="font-bold">1. Cochin Shipyard Limited</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 w-60">
              <h3 className="text-lg font-bold text-gray-400">Silver (3 Lakhs)</h3>
              <ul className="text-left mt-2">
                <li className="font-bold">1. Ospyn Technologies Limited</li>
                <li className="font-bold">2. Turnitin</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 w-60">
              <h3 className="text-lg font-bold text-orange-400">Bronze (2 Lakhs)</h3>
              <ul className="text-left mt-2">
                <li className="font-bold">1. KELTRON</li>
                <li className="font-bold">2. KSPACE</li>
                <li className="font-bold">3. KSITL</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 w-60">
              <h3 className="text-lg font-bold text-gray-600">Brass (1 Lakh)</h3>
              <ul className="text-left mt-2">
                <li className="font-bold">1. Carestack</li>
                <li className="font-bold">2. Typhoon HIL</li>
                <li className="font-bold">3. IT Mission</li>
                <li className="font-bold">4. CDAC</li>
                <li className="font-bold">5. Wadhwani Foundation</li>
                <li className="font-bold">6. Federal Bank</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3: Countdown Timer */}
      <section className="py-12 bg-black text-white text-center">
        <h2 className="text-lg md:text-2xl font-medium mb-4">Countdown to ETIS 2025</h2>
        <div className="flex justify-center space-x-4 md:space-x-8">
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.days || '0'}</span>
            <p className="text-xs md:text-sm">Days</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.hours || '0'}</span>
            <p className="text-xs md:text-sm">Hours</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.minutes || '0'}</span>
            <p className="text-xs md:text-sm">Minutes</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.seconds || '0'}</span>
            <p className="text-xs md:text-sm">Seconds</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Carousel;
