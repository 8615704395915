import React from 'react';

const Sponsorship = () => {
  return (
    <div className="p-6 max-w-3xl mx-auto">
      {/* Heading */}
      <h1 className="text-primary text-3xl md:text-4xl font-bold text-center mb-4">
        Sponsorship Opportunities
      </h1>

      {/* Description */}
      <p className="text-base md:text-lg text-gray-700 text-center mb-8">
        Explore our sponsorship opportunities and become a valued partner in supporting this prestigious event. 
        Download the sponsorship brochure for more details.
      </p>

      {/* Sponsorship Brochure Description and Button */}
      <div className="mb-8 text-center">

        {/* Brochure Download Button */}
        <a
          href="Sponsorship_brochure.pdf" // Replace with the actual link to your brochure
          download
          className="bg-primary text-white px-6 py-3 rounded-full hover:bg-primary-dark transition-colors shadow-md"
        >
          Download Sponsorship Brochure
        </a>
      </div>

      {/* ETIS Sponsorship Benefits Description and Button */}
      <div className="text-center">
        {/* ETIS Sponsorship Benefits Description */}
        <p className="text-base md:text-lg text-gray-700 mb-4">
          Learn more about the benefits of becoming an ETIS Sponsor and how your brand can grow through our partnership. 
          Download the ETIS Sponsorship Benefits document to explore all the exclusive opportunities.
        </p>
        {/* ETIS Sponsorship Benefits Download Button */}
        <a
          href="/ETIS_Sponsor_Benefits.pdf" // Accessing the ETIS file from the public folder
          download
          className="bg-primary text-white px-6 py-3 rounded-full hover:bg-primary-dark transition-colors shadow-md"
        >
          Download ETIS Sponsor Benefits
        </a>
      </div>
    </div>
  );
};

export default Sponsorship;
