import React from 'react';

const EventDetails = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Event Details</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">About the Conference</h2>
        <p className="mb-4">
          ETIS aims to bring together leading academicians, scientists, researchers, and research scholars to exchange and share their experiences.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Who Can Attend?</h2>
        <p className="mb-4">
          Research scholars, UG & PG students, academicians, industry professionals, etc.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Focus Areas</h2>
        <p className="mb-4">
          Technical papers are solicited on subjects pertaining to the scope of the conference, which includes relevant topics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Topics</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li>Artificial Intelligence and Machine Learning</li>
          <li>Advanced Communication Systems</li>
          <li>IoT Innovations and Smart Technologies</li>
          <li>Robotics and Automation</li>
          <li>Healthcare Technologies</li>
          <li>Intelligent Systems for Power Conversion</li>
          <li>Cybersecurity and Ethical Hacking</li>
          <li>Cloud and Edge Computing Paradigms</li>
          <li>Data Science and Analytics</li>
          <li>Intelligent Transportation Electrification Systems</li>
          <li>Smart Grid and Smart Cities</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Expected Number of Attendees</h2>
        <p className="mb-4">
          The expected number of attendees for the conference is 300, which includes 200 authors and 100 non-author delegates.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Paper Submission</h2>
        <p className="mb-4">
          Prospective authors have to submit the original research works in standard formats like IEEE conference templates for review.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Tutorials and Keynotes</h2>
        <p className="mb-4">
          Tutorials and keynote talks on relevant topics will be arranged. Experts from India and abroad will be identified for delivering the tutorials and keynotes.
        </p>
      </section>

      {/* Industry Sessions Section */}
      <section className="mb-8">
        <h2 className="text-blue-600 text-4xl font-bold text-center mb-4">Industry Sessions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Card 1 */}
          <div className="p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md">
            <img
              src="/images/p1.jpg" // Replace with the actual URL of the poster
              alt="AI in E-Governance Poster"
              className="w-full max-w-md h-auto mx-auto rounded-lg mb-4"
            />
            <h3 className="text-lg font-semibold mb-1">Artificial Intelligence in E-Governance</h3>
            <p className="text-sm text-gray-700">
            Join the Industry Panel Session on "Artificial Intelligence in E-Governance" at the ETIS 2025 International Conference, organized by APJ Abdul Kalam Technological University.

              Moderator:

              Dr. Arun Surendran, Principal, Trinity Engineering College

              Panelists:

              1. Dr. Santhosh Babu IAS (Retd.), MD, KSITL, IKM & K-FON
              2. Sri. Sambasiva Rao IAS, Director, Survey & Land Records
              3. Sri. Sandeep Kumar IAS, MD, IT Mission
              4. Sri. Jayaraj Nair, CTO, Ansys

              Discover insights into how AI is transforming governance!

            </p>
            <p className="text-sm text-gray-700 mt-2">
              📅 <strong>Date:</strong> 7th February 2025<br />
              ⏰ <strong>Time:</strong> 5:00 PM - 6:30 PM<br />
              📍 <strong>Venue:</strong> Mar Baselios College of Engineering and Technology, Thiruvananthapuram
            </p>
          </div>

          {/* Card 2 */}
          <div className="p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md">
            <img
              src="/images/p2.jpg" // Replace with the actual URL of the poster
              alt="Smart Grid & Smart E-Mobility Poster"
              className="w-full max-w-md h-auto mx-auto rounded-lg mb-4"
            />
            <h3 className="text-lg font-semibold mb-1">Smart Grid & Smart E-Mobility</h3>
            <p className="text-sm text-gray-700">
            Join the Industry Panel Session on "Smart Grid & Smart E-Mobility at the 𝗘𝗧𝗜𝗦 𝟮𝟬𝟮𝟱 International Conference, organized by 𝗔𝗣𝗝 𝗔𝗯𝗱𝘂𝗹 𝗞𝗮𝗹𝗮𝗺 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗰𝗮𝗹 𝗨𝗻𝗶𝘃𝗲𝗿𝘀𝗶𝘁𝘆. 

            Moderator:

            Sri. C.M. Varghese (MD, EverGreen Energy Technologies Pvt Ltd, Kochi)

            Panelists:

            1. Sri. T.K. Jose IAS (Chairman, Kerala State Electricity Regulatory Commission)
            2. Sri. Narendranath Velluri IFS (CEO, ANERT, Thiruvananthapuram)
            3. Dr. R. Harikumar (Director, Energy Management Centre, Kerala)
            4. Sri. Sunil K (Deputy Chief Engineer, KSEBL)

            Explore innovations in Smart Grid and Smart E-Mobility to shape the future of sustainable energy and transportation.
            </p>
            <p className="text-sm text-gray-700 mt-2">
              📅 <strong>Date:</strong> 8th February 2025<br />
              ⏰ <strong>Time:</strong> 2:00 PM - 3:30 PM<br />
              📍 <strong>Venue:</strong> Mar Baselios College of Engineering and Technology, Thiruvananthapuram
            </p>
          </div>
          {/* Card 3 */}
          <div className="p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md">
            <img
              src="/images/p3.jpg" // Replace with the actual URL of the poster
              alt="Smart Grid & Smart E-Mobility Poster"
              className="w-full max-w-md h-auto mx-auto rounded-lg mb-4"
            />
            <h3 className="text-lg font-semibold mb-1">The Emerging Landscape of Healthcare Technologies</h3>
            <p className="text-sm text-gray-700">
            Join Us for the Industry Panel Session at ETIS 2025! The International Conference on Emerging Technologies for Intelligent Systems (ETIS 2025), organized by APJ Abdul Kalam Technological University, presents an insightful Industry Panel Session on: The Emerging Landscape of Healthcare Technologies 

            Moderator:

            Sri. Balram S (CEO at SCTIMST-TIMed)

            Panelists:

            1. Sri. Subodh P S (Group Head, Health Technology Group, CDAC)

            2. Sri. Rejeesh G R (General Manager, KMTC)

            3. Sri. Abhilash Krishnan (Director of Sales-India, Carestack)

            4. Sri. Bodhish Thomas (Head of DPG Team at Pupillfirst)

            Don't miss this opportunity to explore advancements in healthcare technologies with industry leaders and experts!
            </p>
            <p className="text-sm text-gray-700 mt-2">
              📅 <strong>Date:</strong> 9th February 2025<br />
              ⏰ <strong>Time:</strong> 2:00 PM to 3:30 PM<br />
              📍 <strong>Venue:</strong> Mar Baselios College of Engineering and Technology, Thiruvananthapuram
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventDetails;
