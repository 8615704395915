import React from 'react';

const Accommodation = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      {/* Heading */}
      <h2 className="text-blue-600 text-3xl sm:text-4xl font-bold text-center mb-4 sm:mb-6">
        Accommodation
      </h2>

      {/* Introductory Note */}
      <p className="sm:text-xl text-gray-600 text-center mb-6">
        * Please note that accommodation is not provided by the university. Below is a list of suggested hotels available in Trivandrum for your convenience.
      </p>

      {/* Low Budget Section */}
      <h3 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">
        Low Budget Accommodation
      </h3>
      <ul className="list-disc pl-4 sm:pl-6 text-base sm:text-lg space-y-2 mb-6 sm:mb-8">
        <li>Anies Garden Homestay - Ambalamukku</li>
        <li>Flagship Hotel Pop Tavern - Pattom</li>
        <li>Ashish Tourist Home - Kumarapuram</li>
        <li>New View Hotel Inn - Sreekaryam</li>
        <li>Trivi Property - Near Muttathara</li>
      </ul>

      {/* Medium Budget Section */}
      <h3 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">
        Medium Budget Accommodation
      </h3>
      <ul className="list-disc pl-4 sm:pl-6 text-base sm:text-lg space-y-2 mb-6 sm:mb-8">
        <li>The South Gate by WB Hotels - Thampanoor</li>
        <li>Pattom Royal Hotel - Pattom</li>
        <li>Treebo JP Residency - Pattom</li>
        <li>Ivy Residency - Anayara</li>
      </ul>
    </div>
  );
};

export default Accommodation;
