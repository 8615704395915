import React from 'react';

const AboutUs = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Heading */}
      <h2 className="text-blue-600 text-4xl font-bold text-center mb-6">About Us</h2>
      <h3 className="text-2xl font-bold text-center mb-6">About APJAKTU</h3>

      {/* Paragraph about APJ Abdul Kalam Technological University */}
      <p className="text-lg mb-8">
        APJ Abdul Kalam Technological University, also known as Kerala Technological University (KTU), is a state
        university established by the Government of Kerala in 2014. The university aims to lead technology education
        and research in the state by providing high-quality education in various engineering and technology fields.
        It is committed to fostering innovation, entrepreneurship, and research through its affiliated colleges and
        research centers.
      </p>

      {/* Gallery Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mb-9">
        {/* Image */}
        <div>
          <img
            src="/images/university.jpg"
            alt="APJ Abdul Kalam Technological University campus"
            className="w-full h-auto rounded-md shadow-md"
          />
        </div>
        {/* YouTube Video */}
        <div>
          <iframe
            className="w-full h-64 rounded-md shadow-md"
            src="https://www.youtube.com/embed/0UCuCW8Tfik"
            title="APJ Abdul Kalam Technological University Overview"
            frameBorder="2"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* About Trivandrum Section */}
      <h2 className="text-2xl font-bold text-center mb-6">About Trivandrum</h2>
      <p className="text-lg mb-8">
        Trivandrum, officially known as Thiruvananthapuram, is the capital city of Kerala, India. Known for its rich
        cultural heritage, beautiful beaches, and vibrant history, Trivandrum is a hub of academic and technological
        advancements in the state. The city is home to several prestigious institutions, including the University of
        Kerala, the Indian Space Research Organisation (ISRO), and various other research and educational centers.
      </p>

      {/* Image Gallery for Trivandrum */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mb-9">
        {/* Image 1 */}
        <div>
          <img
            src="/images/trivandrum1.jpg"
            alt="Scenic view of Trivandrum"
            className="w-full h-auto rounded-md shadow-md"
          />
        </div>
        {/* Image 2 */}
        <div>
          <img
            src="/images/trivandrum2.jpg"
            alt="Cultural event in Trivandrum"
            className="w-full h-auto rounded-md shadow-md"
          />
        </div>
      </div>

      {/* About ETIS Conference Section */}
      <h2 className="text-2xl font-bold text-center mb-6">About ETIS Conference</h2>
      <p className="text-lg mb-8">
        APJ Abdul Kalam Technological University organizes its first international conference on Emerging Technologies
        for Intelligent Systems (ETIS2025) from 7-9 February 2025 at Mar Baselios College of Engineering & Technology,
        Trivandrum. ETIS2025 aims to bring together leading academicians, scientists, researchers, and research scholars
        to exchange and share their experiences. It also provides a premier multidisciplinary forum for researchers,
        practitioners, and educators to present and discuss the most recent innovations, trends, practical challenges,
        and solutions in the field of emerging technologies for intelligent systems.
      </p>
      <p className="text-lg mb-8">
        The conference is technically co-sponsored by IEEE Kerala Section and IEEE Industry Applications Society. Focus
        areas of the conference include Artificial Intelligence and Machine Learning, Advanced Communication Systems, IoT
        Innovations and Smart Technologies, Robotics and Automation, Healthcare Technologies, Cyber Security and Ethical
        Hacking, Cloud and Edge Computing, Data Science and Analytics, Intelligent Systems for Power Conversion,
        Intelligent Transportation Electrification Systems, Smart Grid, and Smart Cities.
      </p>
    </div>
  );
};

export default AboutUs;
